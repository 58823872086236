
/* Global Styles */
:root {
  --primary-color: #fe9c02; /* Keep as is */
  --gradient-primary: linear-gradient(45deg, #fe9c02, #ffbe30); /* Upgraded vibrancy */
  --text-color: #333; /* Darkened for contrast */
  --header-color: #fe9c02;
  --accent-color: #ffa726; /* Enhanced vibrancy */
  --primary-color-dark: #121212; /* Deeper darkness for more pop */
  --secondary-color: #e0e0e0; /* Lighter for contrast */
  --background-color: #212121; /* Darkened for mood */
  --surface-color: #4e4e4e; /* Cool tone added */
  --text-color: #e0e0e0; /* Lighter for readability */
  --active-text-color: #fafafa; /* Whitened for attention */
  --secondary-text-color: #757575; /* Grayed for hierarchy */
  --border-color: #424242; /* Borders made noticeable */
  --input-field-background: #303030; /* For field prominence */
  --placeholder-text-color: #9e9e9e; /* Clearer than before */
  --button-color: #ffa726; /* Contrast with primary color */
  --button-text-color: #212121; /* Visibility on buttons */
  --hover-color: #ffb74d; /* Warmer hover feedback */
  --active-color: #ff9800; /* even more vibrant for actions */
  --error-color: #e64a19; /* Stark contrast for error */
  --success-color: #66bb6a; /* Soothing yet visible success */
  --warning-color: #ffeb3b; /* Brighter, more alert warning */
  --info-color: #29b6f6; /* Clear, calm info color */
  --box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Sharper for depth perception */
  --header-text: #b0bec5; /* Light header for readability */
  --side-panel-background-color: #263238; /* Rich background for side panel */
}

body {
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: var(--text-color);
  background-color: var(--primary-color-dark);
  background-size: 450% 450%; /* Enlarged for a subtle effect */
  animation: GradientBG 15s ease infinite; /* Smooth animation for lively feel */
}

@keyframes GradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Add some new CSS for extra flavor */

a {
  color: var(--info-color);
  transition: color 0.2s ease-in-out;
}

a:hover {
  color: var(--hover-color);
}

/* New vibrant buttons */
button, .button {
  background-color: var(--button-color);
  color: var(--button-text-color);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

button:hover, button:focus, .button:hover, .button:focus {
  background-color: var(--hover-color);
  color: var(--active-text-color);
  box-shadow: var(--box-shadow);
}

h1, h2, h3, h4, h5, h6 {
  color: var(--header-color);
}




/* Animations */
@keyframes rotate {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
      transform: translateX(-100%);
  }
  to {
      transform: translateX(0);
  }
}

@keyframes pulse {
  0% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.1);
  }
  100% {
      transform: scale(1);
  }
}

/* Class for animated elements */
.animated {
  animation: fadeIn 2s ease-in;
}

.animated-slide-in-left {
  animation: slideInLeft 0.5s ease-out;
}



/* Example of a specific component style */
.header {
  background: var(--gradient-primary);
  color: white;
  padding: 20px;
  text-align: center;
}

/* Make elements rotate */
.rotate {
  animation: rotate 2s linear infinite;
}

/* Utility classes */
.text-center {
  text-align: center;
}

.margin-top {
  margin-top: 20px;
}

/* Gradients */
.gradient-background {
  background: var(--background-color);
}

/* Responsive design */
@media (max-width: 600px) {
  .header {
      padding: 10px;
  }
}
