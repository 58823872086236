/* SidePanel.css */

.side-panel {
    background-color: var(--surface-color);
    color: var(--secondary-color);
    width: 10vw;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    position: fixed; /* Ensure side panel is fixed */
}

.side-panel-header {
    display: flex;
    align-items: center;
    color: var(--primary-color);
    border-bottom: 2px solid var(--background-color);
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.side-panel-logo {
    width: auto;
    /* Adjust size (keep it auto) for proper scaling */
    height: 40px;
    /* You can set the height you want */
    margin-right: 10px;
    /* This gives breathing room, like a good cup of coffee */
}

.side-panel-title {
    font-size: small;
    margin: 0;
    word-wrap:normal;
}

.side-panel-nav {
    list-style: none;
    padding: 0;
    margin: 0;
}

.side-panel-nav li {
    margin-bottom: 10px;
}

.side-panel-nav li a {
    display: flex;
    align-items: center;
    color: var(--secondary-color);
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 5px;
    transition: all 0.3s;
    
}

.side-panel-nav li a svg {
    margin-right: 8px;
}

.side-panel-nav li a:hover {
    background-color: var(--primary-color);
    color: var(--surface-color);
}

.side-panel-nav li .active {
    background-color: var(--background-color);
    color: var(--primary-color);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
}

.side-panel-profile {
    position: fixed;
    bottom: 0;
    width: calc(10vw - 40px);
    padding: 20px;
    box-sizing: border-box;
    border-top: 2px solid var(--background-color);
    cursor: pointer;
}

.profile-info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.profile-picture {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--secondary-color); /* Placeholder style */
    margin-right: 10px;
}

.profile-name {
    font-weight: bold;
}

.profile-menu {
    position: absolute;
    bottom: 100%;
    left: 20px;
    width: calc(100% - 20px);
    background-color: var(--background-color);
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.2);
    z-index: 2;
}

.profile-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.profile-menu ul li {
    margin-bottom: 10px;
}

.profile-menu ul li a, .profile-menu ul li button {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 10px;
    background: none;
    border: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.profile-menu ul li a:hover, .profile-menu ul li button:hover {
    background-color: var(--secondary-color); /* Adjust as needed */
}

.profile-menu ul li a.active {
    background-color: var(--secondary-color);
    color: white;
}
.profile-menu ul li a svg, .profile-menu ul li button svg {
    margin-right: 8px;
}

.sign-out-button {
    background: none;
    border: none;
    border-radius: 10px;
}
@media (max-width: 1920px) {
    .side-panel {
        width: 15vw;
    }
    .side-panel-profile {
        width: calc(15vw - 40px);
    }
}
/* Icon gradient backgrounds */
.side-panel-nav li[data-icon="mdi-view-dashboard"]:hover .active {
    background: linear-gradient(45deg, #11998e, #38ef7d);
  }
  
  .side-panel-nav li[data-icon="mdi-magnify"]:hover .active {
    background: linear-gradient(45deg, #ff5e62, #ff9966);
  }
  
  .side-panel-nav li[data-icon="mdi-format-list-checks"]:hover .active {
    background: linear-gradient(45deg, #7f00ff, #e100ff);
  }
  
  .side-panel-nav li[data-icon="mdi-robot"]:hover .active {
    background: linear-gradient(45deg, #3a7bd5, #3a6073);
  }
  
  .side-panel-nav li[data-icon="mdi-home-group"]:hover .active {
    background: linear-gradient(45deg, #ff4e50, #f9d423);
  }
  
  .side-panel-nav li[data-icon="mdi-fire"]:hover .active {
    background: linear-gradient(45deg, #ff6a00, #ee0979);
  }
  
  .side-panel-nav li[data-icon="mdi-cog-transfer"]:hover .active {
    background: linear-gradient(45deg, #ff0084, #33001b);
  }
  
  .side-panel-nav li[data-icon="mdi-truck-delivery"]:hover .active {
    background: linear-gradient(45deg, #1d976c, #93f9b9);
  }
  
  .side-panel-nav li[data-icon="mdi-security"]:hover .active {
    background: linear-gradient(45deg, #ffc371, #ff5f6d);
  }
  
  .side-panel-nav li[data-icon="mdi-home-analytics"]:hover .active {
    background: linear-gradient(45deg, #00d2ff, #928dab);
  }
  
  .side-panel-nav li[data-icon="mdi-presentation"]:hover .active {
    background: linear-gradient(45deg, #00f260, #0575e6);
  }
  
  .side-panel-nav li[data-icon="mdi-developer-board"]:hover .active {
    background: linear-gradient(45deg, #f7971e, #ffd200);
  }
  .side-panel-nav li[data-icon="mdi-clock-outline"]:hover .active {
    background: linear-gradient(45deg, #1ea7f7, #5c81fa);
  }
  .side-panel-nav li[data-icon="mdi-key-chain-variant"]:hover .active {
    background: linear-gradient(45deg, #ff6a00, #ee0979);
  }
