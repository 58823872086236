@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500&display=swap');

.need-info-container {
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    padding: 15px;
    background: var(--background-color);
    overflow-y: visible;
    overflow-x: hidden;
    font-family: 'Roboto Mono', monospace;
    position: relative;
}

.input-container {
    width: 48%;
    display: flex;
    flex-direction: column;
}

.explain-container {
    width: 48%;
    padding: 15px;
}

.explain-header, .dropdown-label, .radio-group-label, .text-input-label {
    font-weight: bold;
    color: var(--accent-color);
    text-align: center;
    margin-bottom: 5px;
}

.explain-content, .dropdown-description, .radio-group-description {
    font-size: 0.9em;
    color: var(--text-color);
    margin-bottom: 10px;
}

.dropdown-container, .radio-group, .text-input-container {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid var(--primary-color);
    transition: all 0.3s ease;
}

.dropdown-select, .radio-input, .text-input {
    width: 100%;
    padding: 0.5rem;
    margin-top: 10px;
    background-color: var(--input-field-background);
    color: var(--text-color);
    border: none;
    border-radius: 4px;
    margin-bottom: 20px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.3);
    font-family: 'Roboto Mono', monospace;
}

.dropdown-select::placeholder, .text-input::placeholder {
    color: var(--placeholder-text-color);
}

.dropdown-select:focus, .text-input:focus {
    outline: none;
    border-color: var(--primary-color);
    background-color: var(--surface-color);
}

.radio-label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0;
    cursor: pointer;
    font-family: 'Roboto Mono', monospace;
    user-select: none;
    margin-top: 10px;
    color: var(--text-color);
}

.radio-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    z-index: 100;
    outline: none; /* Removes the focus outline */
    border: none; /* Ensure no border is applied */
}
.radio-label::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 2px solid var(--primary-color);
    border-radius: 50%;
}
.radio-label:hover::after {
    background-color: var(--primary-color);
    opacity: 0.5; /* 50% opacity on hover */
}

.radio-label::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 6px;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 0.3s ease, opacity 0.3s ease;
}


.radio-label.checked::after {
    background-color: var(--primary-color); /* Solid background color for selected */
    opacity: 1; /* Full opacity */
  }


.text-input-container {
    margin-bottom: 15px;
}

.text-input-label {
    font-weight: bold;
    position: relative;
    color: var(--primary-color);
    margin-left: 15px;
  }

.text-input {
    width: 100%; /* Full width for a balanced look */
    padding: 0.5rem;
    margin-top: 10px; /* A little space between label and input */
    background-color: var(--input-field-background);
    color: var(--text-color);
    border: none;
    border-radius: 4px;
    margin-bottom: 20px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.3);
    font-family: 'Roboto Mono', monospace; /* Stylish and consistent font */
  }
.text-input::placeholder {
    color: var(--placeholder-text-color);
}

.text-input:focus {
    outline: none;
    border-color: var(--primary-color);
    background-color: var(--surface-color); /* Highlight the input when focused */
}

/* Additional styles for submit container, checkbox, and button */

.submit-container {
    position: absolute;
    right: 15px;
    bottom: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.submit-button {
    padding: 0.5rem 1rem;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'Roboto Mono', monospace;
    transition: background-color 0.2s ease-in-out;
}

.submit-button:hover {
    background-color: darken(var(--primary-color), 10%);
}

.checkbox-label {
    display: flex;
    align-items: center;
    margin-right: 1rem;
}

.checkbox-input {
    margin-right: 0.5rem;
}



/* Use it for the button hover state */
.submit-button:hover {
    background-color: var(--secondary-color);
}


/* Add the closing animation keyframes */
@keyframes fadeOutScaleDown {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(0.95);
    }
  }
  
  /* Apply the closing animation to the submission message */
  .closing-animation {
    animation: fadeOutScaleDown 2s forwards;
    padding: 1rem;
    text-align: center;
    font-family: 'Roboto Mono', monospace;
    font-size: 1.1em;
    border-radius: 8px;
    background: var(--primary-color);
    color: white;
  }
  
.submitted-message {
    padding: 1rem;
    text-align: center;
    font-family: 'Roboto Mono', monospace;
    font-size: 1.1em;
    border-radius: 8px;
    background: var(--input-field-background);
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.3);
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
}