
.tasks-container {
    padding: 20px;
    background: linear-gradient(45deg, #3a3a3a, #2a2a2a);
    min-height: 100vh;
  }



  h1 {
    animation: slideInGradient 2s ease forwards; /* Adjust the animation duration as needed */
  }
  
  .ola h1 span {
    font-size: 28px;
    position: relative;
  }


  .loading-icon {
    animation: rotate 2s linear infinite;
  }
  .tasks-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
  }



/* Tasks.css */
/* Task card status colors */
.task-card {
  background: var(--surface-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  padding: 20px;
  border-radius: 5px;
  width: calc(33.333% - 20px);
  box-shadow: var(--box-shadow);
  transition: transform 0.3s ease;
  position: relative;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
  transition: box-shadow 0.3s ease, transform 0.1s ease;
  cursor: default;
}

.task-card-completed {
  border-left: 4px solid #4caf50; /* Greenish */
}

.task-card-in-progress {
  border-left: 4px solid #ff9800; /* Orange */
}

.task-name {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: 8px;
}
.ola {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: 8px;
}
.task-id {
  font-size: 0.8rem;
  color: #666;
}

.task-meta p, .task-custom-fields p {
  font-size: 0.9rem;
  margin: 4px 0;
  color: #b7b7b7;
}

.task-meta .task-assignee i,
.task-meta .task-status i,
.task-meta .task-due i,
.task-meta .task-created i,
.task-meta .task-completed-by i,
.task-link i {
  margin-right: 6px;
  color: var(--primary-color);
}

.task-notes {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  color:#b7b7b7;
}

.task-link {
  display: inline-block;
  color: var(--primary-color);
  margin-top: 10px;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.2s ease;
}

.task-link:hover {
  color: var(--secondary-color);
}

/* Hover and animation effects */
@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
}

.task-card:hover {
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
  transform: scale(1.02);
}

.task-card:hover .task-name {
  animation: float 2s infinite;
}

/* Responsive design adjustments */
@media (max-width: 600px) {
  .task-card {
    width: calc(100% - 20px);
  }
  .task-meta p {
    font-size: 0.8rem;
  }
  .task-name, .task-id {
    font-size: 1rem;
  }
}

/* Import Font Awesome for icons */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css');

/* Adjust this variable to set a default icon color */

/* Improved visibility for custom field text */
.task-custom-fields p {
  color: #a1a1a1; /* Or any other color that improves readability */
}

/* Custom fields toggle style */
.task-custom-fields-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 8px;
  font-weight: bold;
  background: var(--surface-color);
  margin-top: 10px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.task-custom-fields-toggle:hover {
  background: var(--primary-color-light); /* Lighter shade of the primary color */
}

.task-custom-fields-toggle i {
  margin-right: 5px;
  transition: transform 0.3s ease;
}

.task-custom-fields-toggle .fa-chevron-up {
  transform: rotate(180deg);
}

/* Add styles for the new icons */
.fa-user-circle, .fa-tasks, .fa-calendar-alt, .fa-clock, .fa-check-circle, .fa-chevron-up, .fa-chevron-down, .fa-external-link-alt {
  color: var(--active-text-color);
}

/* ... your existing styles */

.timeline {
  position: relative;
  margin-left: 100px; /* This is for the timeline to the left */
}

.timeline-item {
  position: relative;
  margin-bottom: 20px; /* Space them out nicely */
}

.timeline-connector {
  position: absolute;
  top: 0;
  left: 50px; /* Half the margin-left size */
  width: 3px;
  height: 100%;
  background: black; /* Or any other color that suits your goth soul */
  z-index: -1;
}

.task-card {
  border-radius: 4px;
  width: 100%; /* Full width within the timeline-item */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px 0;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
}

/* ... adjust other styles as needed to make things look less overwhelming */

/* Pagination styles */
.pagination {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.pagination button {
  margin: 0 10px;
  padding: 10px 20px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #888;
}