/* ChatMessages.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500&display=swap');

.chat-messages {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 50vw;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 20px;
    margin: 20px auto;
    margin-bottom: 20px;
    border-radius: 8px;
    padding: 15px;
}

.chat-messages.initial-margin {
    margin-top: 20px;
}

.code-container {
    position: relative;
    background: var(--background-color);
    border-radius: 6px;
    overflow-x: scroll;
    margin-top: 10px;
    max-width: 40vw;
    align-items: center;
    justify-content: space-around;
    margin-left: 100px;
    margin-right: 100px;
    
}
.code-ribbon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--surface-color);
    padding: 5px 10px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.language-icon {
    color: var(--primary-color);
    /* Additional styling as needed */
}

.copy-button {
    background: none;
    color: var(--primary-color);
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    font-size: 1em;
    transition: all ease-in-out 0.3s;
}

.copy-button:hover {
    color: var(--secondary-color);
    background-color: transparent;
}


/* Markdown Content Styles */
.message-content h1, .message-content h2, .message-content h3 {
    color: var(--secondary-color);
    font-weight: bold;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .chat-messages {
        width: 80vw;
        margin: 0 10vw;
    }
}
.message {
    padding: 10px;
    color: var(--text-color);
    background: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}
.message-section {
    padding: 10px;
    color: var(--text-color);
    transition: all 0.3s ease;
}
.message:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.message-author {
    font-weight: bold;
    margin-bottom: 5px;
    color: var(--primary-color);
}
.message-content {
    margin-left: 20px;
    margin-right: 20px;
    max-width: fit-content;
    text-wrap: wrap;
}
/* Additional Styling */
.message-content a {
    color: var(--secondary-color);
    text-decoration: underline;
}
.code-container pre {
    padding-left: 20px;
    padding-right: 20px;
}
.message-content p code {
    color: var(--primary-color);
    font-weight: bold;
    font-size: large;
    font-family: 'Roboto', monospace;
    margin-left: 20px;
    text-wrap: break-word;
    max-width: fit-content;
}
.message-content a:hover {
    color: var(--primary-color);
}
.error-message {
    color: var(--error-color);
    font-weight: bold;
    text-align: center;
}

.edit-button {
    background: var(--primary-color);
    color: var(--background-color);
    border: none;
    padding: 10px 20px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 20px;
}

.retry-button {
    background: var(--primary-color);
    color: var(--background-color);
    border: none;
    padding: 10px 20px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 20px;
}
.message-section table {
    --tw-border-spacing-x:0px;
    --tw-border-spacing-y:0px;
    border-collapse:separate;
    background: var(--background-color);
    margin-bottom:.25rem;
    margin-top:.25rem;
    width:100%
  }
  .message-section th {
    background-color:var(--primary-color);
    font-weight: bold;
    font-size: large;
    font-family: 'Roboto';
    color: #363636;
    border-bottom-width:1px;
    border-left-width:1px;
    border-top-width:1px;
    padding:.25rem .75rem
  }
  .message-section th:first-child {
    border-top-left-radius:.375rem
  }
  .message-section th:last-child {
    border-right-width:1px;
    border-top-right-radius:.375rem
  }
  .message-section td {
    border-bottom-width:1px;
    border-left-width:1px;
    padding:.25rem .75rem
  }
  .message-section td:last-child {
    border-right-width:1px
  }
    .message-section tbody tr:nth-child(2n) {
        background-color:hsla(0,0%,93%,.2)
    }
    .message-section tbody tr:last-child td:first-child {
        border-bottom-left-radius:.375rem
    }
  .message-section tbody tr:last-child td:first-child {
    border-bottom-left-radius:.375rem
  }
  .message-section tbody tr:last-child td:last-child {
    border-bottom-right-radius:.375rem
  }
.code-container pre {
    overflow-x: scroll; /* Wrap long lines */
    overflow-y: hidden; /* Hide vertical scrollbar */
}

.hljs-comment, .hljs-quote {
    color: #6d9cbe; /* Cool Blue for comments */
}

.hljs-variable, .hljs-template-variable {
    color: #c96765; /* Warm Red for variables */
}

.hljs-attribute {
    color: #f9c859; /* Bright Yellow for attributes */
}

.hljs-tag {
    color: #5ab738; /* Vivid Green for tags */
}

.hljs-name {
    color: #d88537; /* Earthy Orange for names */
}

.hljs-regexp, .hljs-link {
    color: #e37933; /* Bold Orange for regex and links */
}

.hljs-meta {
    color: #557182; /* Deep Sea Blue for meta */
}

.hljs-number, .hljs-built_in, .hljs-builtin-name {
    color: #3986ac; /* Ocean Blue for numbers and built-ins */
}

.hljs-literal {
    color: #569cd6; /* Sky Blue for literals */
}

.hljs-type, .hljs-params {
    color: #ec64e5; /* Rusty Brown for types and params */
}

.hljs-string {
    color: #4dbf99; /* Aquamarine for strings */
}

.hljs-symbol, .hljs-bullet {
    color: #ac4c1e; /* Copper for symbols and bullets */
}

.hljs-title, .hljs-section {
    color: #1c92a9; /* Teal for titles and sections */
}

.hljs-keyword, .hljs-selector-tag {
    color: var(--primary-color); /* Magenta for keywords and selectors */
}

.hljs {
    font-family: 'Roboto Mono', monospace;
    font-weight: 500;
    font-size: 1em;
    line-height: 1.5;
    text-shadow: none;
    white-space: break-spaces;
    overflow-x: scroll;
}

.hljs-emphasis {
    font-style: italic;
}

.hljs-strong {
    font-weight: bold;
}

@keyframes slideAndFadeIn {
    0% {
      transform: translateY(20px);
      opacity: 0;
    }
    50% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes hoverWobble {
    0%, 100% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(5px);
    }
  }
  
  @keyframes fadeOutAndSlideUp {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-20px);
      opacity: 0;
    }
  }
  
  .loading-status {
    font-family: 'Roboto', sans-serif; /* Example font, replace with your choice */
    font-size: 1rem;
    color: var(--primary-color); /* Example color, replace with your choice */
    background-color: transparent;
    
    border-radius: 3px;
    padding: 8px 20px;
    margin: 5px 0;
    display: inline-block;
    animation: slideAndFadeIn 0.5s ease-out forwards, hoverWobble 2s ease-in-out infinite;
    animation-delay: 0s, 0.5s; /* Start the wobble after the slide in */
  }
  
  .loading-status-exit {
    animation: fadeOutAndSlideUp 0.5s ease-in forwards;
  }
  
  /* When a new loading status comes in, the old one gets the 'loading-status-exit' class */