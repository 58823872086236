.chat-container {
    padding: 0; /* Remove padding if it exists */
    display: flex;
    justify-content: center; /* Center children horizontally */
    height: 85vh; /* Adjust height as needed */
}

.chat-area {
    flex-grow: 1;
    display: flex;
    justify-content: center; /* Ensure this is centered as well */
}

.loading-icon {
    animation: rotate 2s linear infinite;
}