
/* ChatHistorySidebar.css */

.chat-history-sidebar {
    background-color: var(--surface-color);
    color: var(--text-color);
    width: 10vw;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    position: fixed;
    right: 0; /* Align to the right side */
    top: 0; /* Stick to the top */
    overflow-y: auto; /* In case of an overflow */
    border-left: 2px solid var(--border-color); /* A subtle separation from the content */
}

/* Mimic the header from the side-panel */
.chat-history-header {
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--primary-color);
    border-bottom: 2px solid var(--background-color);
    padding-bottom: 10px;
    margin-bottom: 20px;
}

/* Button for creating new chat sessions */
.new-chat-button {
    background-color: var(--button-color);
    color: var(--button-text-color);
    width: 100%;
    text-align: center;
    position: relative;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 0.85em;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 20px; /* Give it some breathing room */
    transition: background-color 0.3s; /* Smooth transition for that hover action */
}

.new-chat-button svg {
    margin-right: 5px; /* Let's not crowd our icons */
    text-align: center;
}

.new-chat-button:hover {
    background-color: var(--hover-color);
}

/* The individual session buttons */
.session-button {
    background-color: transparent;
    color: var(--text-color);
    border: none;
    border-radius: 5px;
    padding: 10px;
    text-align: left;
    width: 100%;
    margin-bottom: 2px;
    cursor: pointer;
    transition: background-color 0.3s; /* We love smooth transitions */
    overflow: hidden; /* Keep it tidy */
}

.session-button:hover {
    background-color: var(--hover-color);
}

/* Highlight the active session */
.active-session {
    background-color: var(--active-color);
    font-weight: bold;
    color: var(--button-text-color);
}

/* Adjustments when it's a bit too squished */
@media (max-width: 1920px) {
    .chat-history-sidebar {
        width: 15vw;
    }
}

/* When the session button is a bit too rowdy */
.session-button span {
    white-space: nowrap; /* Keep it on the straight and narrow */
    overflow: hidden; /* No spillover, please */
    text-overflow: ellipsis; /* A classy "..." for overflow text */
}

/* And of course, an engaging loading message */
.chat-history-sidebar p {
    text-align: center; /* Center stage for loading messages */
    font-style: italic; /* A little flair */
}

/* When there's nothing to show, let them know gently */
.chat-history-sidebar p {
    color: var(--secondary-text-color); /* Not too bright, not too dull */
}

/* That's pretty much it! Paste this into your stylesheet and watch it transform before your very eyes! */