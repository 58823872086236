.project-header-container {
    display: flex;
    justify-content: space-around;
    padding: 10px;
    height: 22px;
    align-items: center;
    height: 100px;
    transition: all 0.3s ease;
    width: 97%; /* Full width */
    margin-left: auto;
    margin-right: auto;
    background-size: 600% 100%;

  }
  
  .project-option {
    cursor: pointer;
    padding: 5px;
    transition: all 0.3s ease;
    margin-bottom: 20px;
    color: var(--active-text-color);
    height: 50px;
    font-size: larger;
    text-align: center; /* Center the text */
    flex-grow: 1; /* Grow to fill the space */
    background-size: 600% 100%;
    transform: skew(45deg);

    position: relative; /* For absolute positioning of elements inside */
    transition: background 0.3s ease, flex-grow 0.3s ease; /* Add transition for background and size */
    filter: rgba(0, 0, 0, 0.2); /* Adjust opacity as needed */
}
  .project-option:first-child {
    border-radius: 15px 0 0 15px;
    
  }
  .project-option:last-child {
    border-radius: 0 15px 15px 0;
  }

  .project-option > div {
    transform: skew(-45deg);
    /* Align content back to normal */
  }
  .project-option:hover {
    transform: skew(0deg); /* Remove skew on hover */
    flex-grow: 1.1; /* Slightly increase the size */
  }
  
  .project-option.selected {
    /* Trigger gradient animation on select */
    animation: slideInGradient 2s ease forwards;
  }
  

  .ola {
    color: var(--active-text-color);
    align-content: center;
    padding: 10px;
    padding-left: 20px;
    border-radius: 8px;
    box-shadow: var(--box-shadow);
    margin-bottom: 20px;
    animation: slideInGradient 2s ease forwards;
    background-size: 600% 100%;
  }
  .evolveai {
    align-content: center;
    padding: 10px;
    padding-left: 20px;
    border-radius: 8px;
    box-shadow: var(--box-shadow);
    margin-bottom: 20px;
    animation: slideInGradient 2s ease forwards;
    background-size: 600% 100%;
  }
    
  



  .proserve {
    color: var(--active-text-color);
    align-content: center;
    padding: 10px;
    padding-left: 20px;
    border-radius: 8px;
    box-shadow: var(--box-shadow);
    margin-bottom: 20px;
    animation: slideInGradient 2s ease forwards;
    background-size: 600% 100%;
  }

  .alteryx {
    color: var(--active-text-color);
    align-content: center;
    padding: 10px;
    padding-left: 20px;
    border-radius: 8px;
    box-shadow: var(--box-shadow);
    margin-bottom: 20px;
    animation: slideInGradient 2s ease forwards;
    background-size: 600% 100%;
  }

  .vmware {
    color: var(--active-text-color);
    align-content: center;
    padding: 10px;
    padding-left: 20px;
    border-radius: 8px;
    box-shadow: var(--box-shadow);
    margin-bottom: 20px;
    animation: slideInGradient 2s ease forwards;
    background-size: 600% 100%;
  }

  
  /* Hover effect */
  .project-option:hover {
    flex-grow: 1.1;
  }
  
  @keyframes slideOpen {
    0% {
      flex-grow: 1;
    }
    100% {
      flex-grow: 2; /* Selected project expands more */
    }
  }
  .project-icon {
    position: relative;
    margin-right: 10px;
    top: 6px;
    transition: top 0.3s ease, margin-right 0.3s ease;
    color: #3e7da6;
  }
  .vmware-project-icon {
    position: relative;
    margin-right: 10px;
    top: 6px;
    transition: top 0.3s ease, margin-right 0.3s ease;
    color: #4ca4a8;
  }
  .alteryx-project-icon {
    position: relative;
    margin-right: 10px;
    top: 6px;
    transition: top 0.3s ease, margin-right 0.3s ease;
    color: #0082CA;
  }
  .ola-project-icon {
    position: relative;
    margin-right: 10px;
    top: 6px;
    transition: top 0.3s ease, margin-right 0.3s ease;
    color: #FF9900;
  }
  .gcp-project-icon {
    position: relative;
    margin-right: 10px;
    top: 60px;
    transition: top 0.3s ease, margin-right 0.3s ease;
    color: #3e7da6;
  }
  .proserve-project-icon {
    position: relative;
    margin-right: 10px;
    top: 6px;
    transition: top 0.3s ease, margin-right 0.3s ease;
    color: #3e7da6;
  }
  .evolveai-project-icon {
    position: relative;
    margin-right: 10px;
    top: 6px;
    transition: top 0.3s ease, margin-right 0.3s ease;
    color: #fe9c02;
  }
  .project-title.selected{
    position: relative;
    top: 0; /* Adjust as needed */
    margin-right: 0; /* Adjust as needed */
    transition: top 0.3s ease, margin-right 0.3s ease;
    color: #3e7da6;
  }
  .vmware-project-title.selected {
    position: relative;
    top: 0; /* Adjust as needed */
    margin-right: 0; /* Adjust as needed */
    transition: top 0.3s ease, margin-right 0.3s ease;
    color: #4ca4a8;
  }
  .alteryx-project-title.selected {
    position: relative;
    top: 0; /* Adjust as needed */
    margin-right: 0; /* Adjust as needed */
    transition: top 0.3s ease, margin-right 0.3s ease;
    color: #0082CA;
  }
  .ola-project-title.selected {
    position: relative;
    top: 0; /* Adjust as needed */
    margin-right: 0; /* Adjust as needed */
    transition: top 0.3s ease, margin-right 0.3s ease;
    color: #FF9900;
  }
  .gcp-project-title.selected {
    position: relative;
    margin-right: 10px;
    top: 0; /* Adjust as needed */
    margin-right: 0; /* Adjust as needed */
    transition: top 0.3s ease, margin-right 0.3s ease;
    color: #3e7da6;
  }
  .proserve-project-title.selected {
    position: relative;
    top: 0; /* Adjust as needed */
    margin-right: 0; /* Adjust as needed */
    transition: top 0.3s ease, margin-right 0.3s ease;
    color: #3e7da6;
  }
  .evolveai-project-title.selected {
    position: relative;
    top: 0; /* Adjust as needed */
    margin-right: 0; /* Adjust as needed */
    transition: top 0.3s ease, margin-right 0.3s ease;
    color: #fe9c02;
  }

  .project-content {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: skew(45deg); /* Counter-skew for content */
}

.project-icon {
    /* Adjust the size, color, and margin as needed */
    margin-right: 10px;
}

.project-title {
    position: relative;
    right: 40px;
    font-weight: bold;
    color: var(--active-text-color);
    transition: color 0.3s ease;
    top: 20px;
}

/* Selected project styling */
.project-content.selected .project-title,
.project-content.selected .project-icon {
    color: #FFD700; /* Gold color for selection */
    font-size: 1.2em; /* Make it slightly larger */
}

/* Hover effect */
.project-option:hover .project-title,
.project-option:hover .project-icon {
    color: #FFF; /* Change color on hover */
}

/* Animation for selecting a project */
@keyframes pop {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
}

.project-option.selected {
    flex-grow: 2; /* Selected project takes more space */
    animation: pop 0.5s ease slideInGradient 2s ease-in-out backwards;
}


@keyframes slideInGradient {
    0% {
        background-size: 600% 100%;
        background-color: rgba(0, 0, 0, 0.2);
    }
    100% {
        background-size: 100% 100%;
        background-color: transparent;
    }
}

@keyframes slideOutGradient {
    from {
        background-size: 100% 100%;
        background-color: transparent;
    }
    to {
        background-size: 600% 100%;
        background-color: rgba(0, 0, 0, 0.2);
    }
}
.project-option.unselected {
    animation: slideOutGradient 2s ease forwards;
    background-size: 600% 100%;
    background-color: rgba(0, 0, 0, 0.2)
}



/* Project Header Temp Styles */

.project-icon {
  position: relative;

  transition: top 0.3s ease, margin-right 0.3s ease;
  color: #e0e0e0;
}
.vmware-project-icon {
  position: relative;

  transition: top 0.3s ease, margin-right 0.3s ease;
  color: #e0e0e0;
}
.alteryx-project-icon {
  position: relative;

  transition: top 0.3s ease, margin-right 0.3s ease;
  color: #e0e0e0;
}
.ola-project-icon {
  position: relative;

  transition: top 0.3s ease, margin-right 0.3s ease;
  color: #e0e0e0;
}
.gcp-project-icon {
  position: relative;

  transition: top 0.3s ease, margin-right 0.3s ease;
  color: #e0e0e0;
}
.proserve-project-icon {
  position: relative;

  transition: top 0.3s ease, margin-right 0.3s ease;
  color: #e0e0e0;
}
.evolveai-project-icon {
  position: relative;

  transition: top 0.3s ease, margin-right 0.3s ease;
  color: #e0e0e0;
}
.project-title.selected{
  position: relative;

  transition: top 0.3s ease, margin-right 0.3s ease;
  color: #e0e0e0;
}
.vmware-project-title.selected {
  position: relative;

  transition: top 0.3s ease, margin-right 0.3s ease;
  color: #e0e0e0;
}
.alteryx-project-title.selected {
  position: relative;

  transition: top 0.3s ease, margin-right 0.3s ease;
  color: #e0e0e0;
}
.ola-project-title.selected {
  position: relative;

  transition: top 0.3s ease, margin-right 0.3s ease;
  color: #e0e0e0;
}
.gcp-project-title.selected {
  position: relative;


  transition: top 0.3s ease, margin-right 0.3s ease;
  color: #e0e0e0;
}
.proserve-project-title.selected {
  position: relative;

  transition: top 0.3s ease, margin-right 0.3s ease;
  color: #e0e0e0;
}
.evolveai-project-title.selected {
  position: relative;
  transition: top 0.3s ease, margin-right 0.3s ease;
  color: #e0e0e0;
}
