/* SectionContainer.css */

.section-container {
    background-color: var(--surface-color);
    /* Matches the side panel for consistency */
    color: var(--text-color);
    /* Inherits the global text color for readability */
    padding: 20px;
    /* Spacing inside the container */
    margin: 20px auto;
    /* Spacing outside the container, centered */
    border-radius: 8px;
    /* Rounded corners for that modern charisma */
    box-shadow: var(--box-shadow);
    /* Elevation effect for that sweet, sweet depth */
    overflow: hidden;
    /* Content spillage is a no-no */
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    /* Smooth as jazz */
    max-width: 1300px;
    /* Maximum width to prevent it from getting too chubby */
}

.section-container:hover {
    transform: translateY(-5px);
    /* Hover effect, because we're fancy */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
    /* Deeper shadow for drama */
}

/* Responsive adjustments for smaller displays */
@media (max-width: 600px) {
    .section-container {
        margin: 10px;
        /* Less margin for those tight spaces */
        padding: 10px;
        /* Less padding for more content */
        max-width: 98vw;
        /* Give it all we've got! */
    }
}