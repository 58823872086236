
/* Styles for main content area */
.main-content {
    flex: 1;
    overflow-y: auto; /* Smooth scroll baby */
    margin-left: 10vw; /* 200px - gives room for that snazzy side panel */
    padding: 2rem; /* Spacious padding for breathing room */
    background-color: var(--background-color); /* Consistent background */
    min-height: calc(100vh - 4rem); /* Full view height minus padding */
    transition: margin-left 0.3s; /* Smooth transition for responsive designs */
}

@media (max-width: 1920px) {
  .main-content {
    margin-left: calc(15vw + 40px); /* Adjust based on media query */
  }
}