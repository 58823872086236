
/* Styles for fileUpload */
.file-upload {
    position: absolute; /* Updated from default */
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--text-color-light); /* Brighter text for contrast */
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease; /* Smooth all transitions */
    border-radius: 20px; /* Aerodynamic curves */
    width: 300px; /* Width in pixels for control */
    padding: 15px; /* More padding for a plusher feel */
    background-color: var(--surface-color); /* Changed for unified theme */
    box-shadow: var(--box-shadow); /* Consistent depth perception */
}

.file-upload.submitted {
    top: 10%; /* Closer to the user's eye line upon submission */
}

.file-upload.has-file {
    border-radius: 12px; /* Slightly less curvy for files */
}


/* Styles for fileLabel */
.file-label {
    border: 2px solid var(--surface-color); /* Default border */
    color: var(--text-color);
    padding: 6px 18px;
    background-color: transparent; /* Default background-color */
    border-radius: 20px; /* Default border-radius */
    left: 5px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s, border-radius 0.3s;
}

.file-label.has-file {
    border: 2px solid transparent;
    background-color: var(--accent-color);
    border-radius: 10px;
}

/* Styles for fileName */
.file-name {
    text-align: center;
    color: var(--text-color);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 10px;
}

/* Styles for uploadButton */
.upload-button {
    border: none;
    outline: none;
    padding: 8px 20px;
    cursor: not-allowed; /* Default cursor */
    background-color: var(--hover-color); /* Default background-color */
    border-radius: 20px; /* Default border-radius */
    transition: all 0.3s;
    color: var(--text-color);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.upload-button.has-file {
    cursor: pointer;
    background-color: var(--accent-color);
    border-radius: 10px;
}

/* Styles for fileInput */
.file-input {
    display: none;
}
