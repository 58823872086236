
.type-container, .inner-container {
  border-radius: 10px;
  margin: 1rem auto;
  background-color: var(--surface-color);
  max-width: 800px;  
  padding: 1.5rem;
}

.highlight {
  color: var(--active-color);  
}

.clickable-title {
  color: var(--info-color);
  cursor: pointer;
}

.search-bar {
  display: flex;
  justify-content: center;
  padding: 15px 20px;
  background-color: var(--surface-color);  
  animation: slideInLeft 0.5s ease-out;
}

.search-button {
  border: none;
  background-color: var(--button-color);
  padding: 0px 20px;
  cursor: pointer;
  border-radius: 5px;  
}

.search-button:hover, .search-button:focus {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
}

.search-input {
  background-color: var(--input-field-background);
  color: var(--text-color-light);
  padding: 0.5rem;
  margin-right: 1rem;
  width: 15vw;
  border: none;
  border-radius: 5px;  
}

.search-input:focus {
  outline: none;
  box-shadow: none;
}

.text-container {
  margin-top: 10px;
  margin-bottom: 10px;
}

.bar-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.logo-header {
  width: 15%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.document-item h3 {
  font-weight: bold;
}

.document-item .mdi-icon {
  cursor: pointer;
}

.document-item {
  transition: all 0.3s;
}

.document-item:hover {
  background-color: var(--secondary-text-color);
}

.document-excerpt {
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 10px;
  border-bottom: solid 1px var(--border-color);
}

.qcon {
  margin-right: 15px;
  color: var(--button-color);
}

.answer {
  margin-left: 60px;
  margin-right: 60px;
}
.no-results-create{
  font-weight: bold;
  background-color: transparent;
  color: var(--primary-color);
  transition: all 0.3s;
  cursor: pointer;
}

.no-results-create button:hover {
  color: var(--accent-color);
  transform: translateY(-10px);
}
.search-icon, .loading-icon, .error-icon, .section-icon, .title-icon {
  margin-right: 8px;
  color: var(--primary-color);
}
.button-icon {
  color: var(--surface-color);
}
.loading-icon {
  animation: rotate 2s linear infinite;
}

.error-icon {
  color: var(--error-color);
}

.title-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  
}

.title-container:hover {
  color: var(--primary-color);
  transform: translateY(-5px);
}

.title-icon {
  color: var(--accent-color);
}

.open-icon {
  margin-left: 20px;
  color: var(--info-color);
  cursor: pointer;
  transition: all 0.3s;
}

.open-icon:hover {
  color: var(--hover-color);
  transform: translateY(-5px);
}

.pdf-viewer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.pdf-viewer > iframe {
  max-width: 100vw;
  max-height: 100vh;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  transition: transform 0.3s;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.pdf-viewer .viewer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
  height: 75vh;
  z-index: 1000;
  animation: slideDown 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

.pdf-viewer .close-btn {
  position: absolute;
  right: -15px;
  top: -15px;
  background-color: rgb(240, 131, 153);
  color: var(--secondary-text-color);
  border: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  padding: 2.5px;
  cursor: pointer;
  z-index: 1001;
}

.pdf-viewer .close-btn:hover {
  background-color: crimson;
  color: var(--button-color);
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translate(-50%, -45%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

.faq-container {
  margin-top: 30px;
}

.faq-item {
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px var(--border-color);
  transition: all 0.3s;
}

.faq-item:hover {
  background-color: var(--secondary-text-color);
  transform: translateY(-5px);
}

.question {
  font-weight: bold;
}


/* Insert this into your current CSS file */

/* Modal and button styles */
.edit-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.modal-content {
  background: var(--surface-color);
  padding: 2rem;
  border-radius: 0.5rem;
  width: 90%;
  max-width: 500px;
}

.modal-content input,
.modal-content textarea {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  border: 1px solid var(--border-color);
  resize: none; /* Lock that resize action down! */
}

.modal-content button {
  background: var(--button-color);
  color: var(--text-color);
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

.close-modal {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--error-color); /* Uh-oh, emergency exit color! */
}

/* Icon buttons for FAQ items */
.faq-item-controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.5rem;
}

.faq-item-control-btn {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 0.5rem;
}

/* Style for the ellipsis icon button */
.faq-item-ellipsis {
  font-size: 1.5rem;
  color: var(--button-color);
  cursor: pointer;
  transition: all 0.3s ease;
}

.faq-item-ellipsis:hover {
  color: var(--hover-color);
  transform: translateY(-5px);
}

/* Add some polish to the icon buttons */
.icon-btn {
  color: var(--button-color);
  padding: 0.25rem;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.icon-btn:hover {
  background-color: var(--hover-background-color);
  color: var(--hover-color);
  transform: translateY(-5px);
}

/* ... Your existing styles ... */

/* Modal transition styles */
.modal-entering,
.modal-entered {
  animation: fadeInUp 0.3s forwards;
}

.modal-exiting,
.modal-exited {
  animation: fadeOutDown 0.3s forwards;
}

/* Modal keyframes */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(30px);
  }
}

/* Textarea auto-resize */
.modal-content textarea {
  overflow-y: hidden; /* Hide the scrollbar */
}

/* ... Your existing styles ... */