
/* Terminal.css */
.terminal-container {
    background-color: #1e1e1e;
    color: #c5c8c6;
    border-radius: 8px;
    padding: 10px;
    position: relative;
    font-family: 'Source Code Pro', monospace;
    font-size: 12px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-width: 40vw;
    max-height: 100%; /* Set maximum height to respect container's height */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-left: 100px;
    margin-right: 100px;
  }
  
  /* Parent container will need to manage the layout */
  .chat-interface {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers the chat components on the cross axis */
    height: 100%; /* Set to the desired height */
  }
  
  .terminal-header {
    display: flex;
    margin-left: 0px;
    margin-right: 100px;
    align-items: center;
    padding: 5px 10px;
    width: 100%;
    background-color: #333;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  .terminal-buttons {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .terminal-button {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
  }
  
  .terminal-button-exit {
    background-color: #ff5f56;
  }
  
  .terminal-button-minimize {
    background-color: #ffbd2e;
  }
  
  .terminal-button-expand {
    background-color: #27c93f;
  }
  
  .terminal-title {
    color: #c5c8c6;
    font-weight: bold;
    flex-grow: 1;
    text-align: center;
    margin-right: 50px;
  }
  
  .terminal-body {
    padding: 10px;
    overflow-y: auto;
    flex-grow: 1; /* Allows the terminal body to grow and fill the space */
  }
  
  .terminal-line {
    white-space: pre-wrap; /* To ensure long lines wrap and don't expand horizontally */
    overflow-wrap: break-word; /* Break lines if there are no spaces to wrap */
  }