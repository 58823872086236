
/* Deployment.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500&display=swap');

.parameters-container {
    border-radius: 8px;
    padding: 15px;
    background: var(--background-color); /* Use the same variable you used for parameters container */
    overflow-y: visible;
    overflow-x: hidden;
    font-family: 'Roboto Mono', monospace;
  }

.template-header {
  font-weight: bold;
  color: var(--accent-color);
  text-align: center; /* Center the header for a nice touch */
  margin-bottom: 5px; /* Spacing for aesthetic */
}

.parameter {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  padding: 10px;
  display: flex;
  flex-direction: column; /* This ensures the label is above input */
  color: var(--primary-color);
  transition: all 0.3s ease;
  align-items: flex-start; /* Align to the start to keep things tidy */
}


.parameter:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.parameter-label {
  font-weight: bold;
  position: relative;
  color: var(--primary-color);
  margin-left: 15px;
}


.parameter-input {
  width: 100%; /* Full width for a balanced look */
  padding: 0.5rem;
  margin-top: 10px; /* A little space between label and input */
  background-color: var(--input-field-background);
  color: var(--text-color);
  border: none;
  border-radius: 4px;
  margin-bottom: 20px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.3);
  font-family: 'Roboto Mono', monospace; /* Stylish and consistent font */
}

.parameter-input::placeholder {
  color: var(--placeholder-text-color);
}

.parameter-input:focus {
  outline: none;
  border-color: var(--primary-color);
  background-color: var(--surface-color); /* Highlight the input when focused */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .parameters-container {
    width: 80vw;
    margin: 0 10vw;
  }
}

/* Borrowing styles from ChatMessages.css for consistency */
.message-content p code {
  color: var(--primary-color);
  font-weight: bold;
  font-size: large;
  font-family: 'Roboto', monospace;
}

.deployment-header {
  font-family: 'Roboto', sans-serif;
  font-size: 2rem;
  background: var(--gradient-primary);
  padding: 10px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 5px;
  border-radius: 9px;
}
.header-text {
    color: #363636;
    font-size: 1.75rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    margin-right: 25px;
}

.deployment-container {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    max-width: 40vw; /* Set a max width similar to .code-container */
    overflow-y:visible;
    overflow-x: hidden;
    border-radius: 8px;
    padding: 15px;
    height: fit-content;
    width: 45vw; /* Set a width similar to .chat-messages */
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 50vw; /* Set a max width similar to .code-container */
    background: var(--background-color);
    align-items: stretch; /* Align children width to stretch the container width */
    font-family: 'Roboto Mono', monospace; /* Consistent font with ChatMessages */
    }
  
  .parameters-container, .resources-container {
    flex-basis: 50%;
  }
  
  .resources-container {
    border-radius: 8px;
    padding: 15px;
    background: var(--background-color); /* Use the same variable you used for parameters container */
    overflow-y: visible;
    overflow-x: hidden;
    font-family: 'Roboto Mono', monospace;
  }
  
  .resources-list {
    list-style: none;
    padding: 0;
  }
  
  .resource-item {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 10px;
    transition: all 0.3s ease;
    cursor: default; /* Indicates you can't interact with the element */
  }
  
  .resource-item:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }
/* Add this to your existing Deployment.css */

.svg-container {
  text-align: center; /* Centers the SVG in its container */
  padding: 10px;
  margin-top: 15px; /* Adds space above the SVG container */
  border-radius: 8px; /* Rounds the corners */
  background: rgba(255, 255, 255, 0.1); /* Subtle background for contrast */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}

.png-container {
  max-width: 100%; /* Ensures the SVG is responsive and fits the container */
  height: auto; /* Maintains the aspect ratio of the SVG */
  border-radius: 6px; /* Rounds the corners of the SVG itself */
}

.png-container:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3); /* Enhances shadow on hover */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .svg-container {
    width: 80%; /* Adjusts the width on smaller screens */
    margin: 0 auto; /* Centers the container */
  }
}
