
/* ChatArea.css */

.chat-area {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: var(--background-color); /* No chat's complete without a gorgeous backdrop */
    border-left: 1px solid var(--border-color); /* Like the Berlin Wall, but for separating elements */
    position: relative; /* Positioning it like a boss */
  }
  
  /* Placeholder style for when you're all alone with no chats */
  .chat-area__placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: var(--secondary-text-color); /* Looking faded, like my jeans */
    font-style: italic; /* Because straight is too mainstream */
  }
  
  /* Chat messages are scrollable */
  .chat-messages {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px; /* Padding, because comfort is king */
  }
  
  /* The hidden signal to the last message */
  div.chat-messages::after {
    content: "";
    padding-bottom: 10px; /* A little space to breathe at the bottom */
  }
  
  /* Some scroll bar magic for smoother scrolling */
  .chat-messages::-webkit-scrollbar {
    width: 8px; /* Skinny legend */
  }
  
  /* Thumb of the scrollbar */
  .chat-messages::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar); /* Because scrollbar fashion is a thing */
    border-radius: 4px; /* Rounded for your pleasure */
  }